import React, { useEffect } from 'react'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box as Page } from '@node-space/storybook-components/dist/Box'
import { Headline } from 'components/text/Headline'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useActivityQuery } from 'hooks/queries/useActivityQuery'
import { useMappedRoles } from 'hooks/useMappedRoles'
import {
  AmplitudeEvent,
  AmplitudeEventAction,
  AmplitudeEventCategory,
} from 'utils/amplitude/amplitudeEvents'
import track from 'utils/tracker'
import { LatestActivity } from '../LatestActivity'
import { GetStartedSteps } from './GetStartedSteps'

export const DevXGetStarted = () => {
  const { t } = useTranslation()

  const { profile } = useProfileContext()

  const permissions = useMappedRoles()
  const canViewApiKeys = permissions?.manageApiIntegration?.canView

  const { data: activityData, isPending } = useActivityQuery(canViewApiKeys)
  const apiKeyList = activityData?.apiKeysList
  const merchantList = activityData?.merchantList
  const paymentsList = activityData?.paymentsList

  useEffect(() => {
    track.Amp.track(AmplitudeEvent.DEVX_HOME, {
      category: AmplitudeEventCategory.MERCHANT_PORTAL,
      action: AmplitudeEventAction.VIEW,
    })
  }, [])

  return (
    <Page width="full" maxWidth={896} centerBox paddingY={24}>
      <Headline>{`${t('getStarted.title')}, ${profile?.firstName}`}</Headline>
      <GetStartedSteps
        isLoading={isPending}
        canViewApiKeys={canViewApiKeys}
        hasApiKeys={!isEmpty(apiKeyList)}
        hasMerchants={!isEmpty(merchantList)}
        hasPayments={!isEmpty(paymentsList)}
      />
      <LatestActivity isMerchantsLoading={isPending} merchantList={merchantList} />
    </Page>
  )
}
