import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box as WalletsHeader, Button, DoubleLabelSelect } from '@node-space/storybook-components'
import track from 'utils/tracker'
import { Description } from 'components/text/Description'
import { MerchantType } from 'types/types'
import { PathNames } from 'constants/General'

type LatestMerchantsProps = {
  merchants: MerchantType[]
  selectedId: string
  onSelectMerchant: (MerchantType) => void
}

const LatestMerchants = ({ merchants, selectedId, onSelectMerchant }: LatestMerchantsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const merchantOptions = merchants?.map(
    ({
      merchantId,
      wallet: {
        id: walletId,
        balance,
        description,
        currency: { code: currencyCode },
      },
      displayName,
    }) => {
      return {
        icon: currencyCode?.toLowerCase(),
        label: `${displayName} ...${merchantId?.substr(-8, 8)}`,
        secondLabel: `${balance} ${currencyCode} (${description})`,
        value: `${walletId}`,
      }
    }
  )

  return (
    <>
      <WalletsHeader flex justifyContent="between" alignItems="center">
        <Description>{t('latestMerchants.description')}</Description>
        <Button
          onClick={() => {
            track.Hotjar.event('user_clicked_dashboard_view_all_merchants')
            track.GA.event({
              category: 'Dashboard',
              action: 'click',
              label: 'Button: View all merchants',
            })

            navigate( `${PathNames.SETTINGS}${PathNames.MANAGE_MERCHANT_DETAILS}`)
          }}
        >
          {t('latestMerchants.viewAll')}
        </Button>
      </WalletsHeader>
      <DoubleLabelSelect
        iconsUrl={process.env.ICONS_URL}
        value={selectedId}
        options={merchantOptions}
        menuMaxHeight={200}
        onChange={value => onSelectMerchant(value)}
      />
    </>
  )
}

export default LatestMerchants
