import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { take } from 'lodash-es'
import { Box, Loader, Text } from '@node-space/storybook-components'
import { Description } from 'components/text/Description'
import { Headline } from 'components/text/Headline'
import EmptyContainer from 'components/EmptyContainer'
import LatestMerchants from './LatestMerchants'
import LatestTransactions from './LatestTransactions'
import { MerchantType } from 'types/types'

type LatestActivityType = {
  isMerchantsLoading: boolean
  merchantList: MerchantType[]
}

export const LatestActivity = ({ isMerchantsLoading, merchantList }: LatestActivityType) => {
  const { t } = useTranslation()

  const LATEST_MERCHANTS_LIMIT = 4
  const latestMerchants = take(merchantList, LATEST_MERCHANTS_LIMIT)
  const hasMerchants = !!latestMerchants?.length

  const [selectedWalletId, setSelectedWalletId] = useState('')

  useEffect(() => {
    if (hasMerchants && !selectedWalletId) {
      const defaultWalletId = `${latestMerchants[0].wallet?.id}`
      setSelectedWalletId(defaultWalletId)
    }
  }, [merchantList])

  return (
    <Box flex direction="col" paddingT={24} gapY={16}>
      <Headline>{t('latestActivity')}</Headline>
      {!!isMerchantsLoading ? (
        <EmptyContainer hasGap={false}>
          <Loader theme="dark" size="medium" />
        </EmptyContainer>
      ) : hasMerchants ? (
        <Box flex direction="col" gapY={24}>
          <LatestMerchants
            merchants={latestMerchants}
            selectedId={selectedWalletId}
            onSelectMerchant={walletId => {
              setSelectedWalletId(walletId)
            }}
          />
          <Text tag="h4" weight="semibold">
            {t('latestTransactions.description')}
          </Text>
          <LatestTransactions walletId={selectedWalletId} />
        </Box>
      ) : (
        <Description>{t('latestActivityDescription')}</Description>
      )}
    </Box>
  )
}
