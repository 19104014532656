import { requestApi } from 'api/api'
import { ApiKey } from 'types/apiKeys'
import { PaymentsResponse } from 'types/payments'
import { MerchantType } from 'types/types'

export const getActivity = async (canViewApiKeys: boolean) => {
  let apiKeysList = []
  if (canViewApiKeys) {
    const apiKeysListData: ApiKey[] = await requestApi({
      url: '/token/withdraw',
      method: 'GET',
    })
    apiKeysList = apiKeysListData
  }

  const merchantList: MerchantType[] = await requestApi({
    url: '/v1/merchant',
    method: 'GET',
  })

  const paymentsList: PaymentsResponse[] = await requestApi({
    url: '/v1/pay/ui',
    method: 'GET',
  })

  const data = {
    apiKeysList,
    merchantList,
    paymentsList,
  }

  return data
}
