import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { getActivity } from 'services/ActivityService'
import { QueryMetaType } from 'types/reactQuery'
import { isTestEnvironment } from 'utils/utils'

export const useActivityQuery = (canViewApiKeys = true) => {
  const { isAuthed } = useAuth()

  const meta: QueryMetaType = {
    isErrorFatal: true,
  }

  return useQuery({
    queryKey: reactQueryKeys.activity(),
    queryFn: () => getActivity(canViewApiKeys),
    enabled: isTestEnvironment || !!isAuthed,
    meta,
  })
}
