import React from 'react'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Box, Loader, SimpleTableV2 } from '@node-space/storybook-components'
import EmptyContainer from 'components/EmptyContainer'
import { Description } from 'components/text/Description'
import { useTransactionsQuery } from 'hooks/queries'
import { setPaymentDescription } from 'utils/transactionText'

const LatestTransactions = ({ walletId }) => {
  const { t } = useTranslation()

  const {
    data: transactionData,
    isPending,
    isError,
  } = useTransactionsQuery({
    max: 10,
    offset: 0,
    walletId,
  })
  const latestTransactions = transactionData?.transactions

  const colPadding = 'px-3 py-4 whitespace-nowrap'

  const tableColumns = [
    { headerTitle: t('latestTransactions.labels.currency'), className: colPadding },
    { headerTitle: t('latestTransactions.labels.date'), className: colPadding },
    { headerTitle: t('latestTransactions.labels.transactionId'), className: colPadding },
    { headerTitle: t('latestTransactions.labels.description'), className: colPadding },
    { headerTitle: t('latestTransactions.labels.amount'), className: colPadding },
    { headerTitle: t('latestTransactions.labels.hash'), className: colPadding },
  ]

  const tableData = latestTransactions?.map(transaction => ({
    rowData: [
      {
        cellData: transaction?.wallet?.currency?.code,
        className: colPadding,
      },
      {
        cellData: dayjs(transaction.dateCreated).format('DD MMM YYYY HH:mm:ss'),
        className: colPadding,
      },
      {
        cellData: transaction?.id,
        className: colPadding,
      },
      {
        cellData: setPaymentDescription(transaction),
        className: colPadding,
      },
      {
        cellData: transaction?.amount,
        className: colPadding,
      },
      {
        cellData: transaction?.hash
          ? transaction?.hash?.includes('offChain')
            ? t('offChain')
            : transaction?.context?.hash
          : '-',
        className: colPadding,
      },
    ],
  }))

  if (isPending)
    return (
      <EmptyContainer hasGap={false}>
        <Loader theme="dark" size="medium" />
      </EmptyContainer>
    )

  if (isError)
    return (
      <EmptyContainer hasGap={false}>
        <Description>{t('latestTransactions.error')}</Description>
      </EmptyContainer>
    )

  if (isEmpty(latestTransactions))
    return (
      <EmptyContainer hasGap={false}>
        <Description>{t('latestTransactions.empty')}</Description>
      </EmptyContainer>
    )

  return (
    <Box border="gray" background="white" borderRadius={8} overflow="hidden">
      <SimpleTableV2 columns={tableColumns} tableData={tableData} />
    </Box>
  )
}

export default LatestTransactions
